import React from 'react';
import { CatalogTypes } from 'catalog-lib';
import { Button } from 'components';
import { useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
	buttonContainerDesktop: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: theme.spacing(2)
	},
	styledButtonDesktop: {
		[theme.breakpoints.down('md')]: {
			height: theme.spacing(3)
		}
	},
	darkerHoverButtonDesktop: {
		'&:hover': {
			backgroundColor: theme.palette.secondary[700]
		}
	},
	buttonContainer: {
		display: 'flex',
		gap: theme.spacing(3),

		[theme.breakpoints.down('md')]: {
			gap: theme.spacing(1)
		},
		...theme.border({ width: 1, kind: 'top', color: '#DCDCDC', radius: 0 }),
		'& a:nth-child(1)': {
			...theme.border({ width: 1, kind: 'right', color: '#DCDCDC', radius: 0 })
		}
	},
	styledButton: {
		width: '50%',
		padding: theme.spacing(1)
	}
}));

type CourseActionButtonsProps = Pick<CatalogTypes.CourseTableItem, 'courseURL' | 'bookingURL'> & {
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	dividedButtons: boolean;
};

export const CourseActionButtons = (props: CourseActionButtonsProps) => {
	const { courseURL, bookingURL, onClick, dividedButtons } = props;
	const classes = useStyles();
	const { formatMessage } = useIntl();

	return (
		<div className={dividedButtons ? classes.buttonContainerDesktop : classes.buttonContainer}>
			<Button
				className={dividedButtons ? classes.styledButtonDesktop : classes.styledButton}
				variant="text"
				color="secondary"
				href={courseURL}
				onClick={onClick}
				unstyled
			>
				{formatMessage({ id: 'catalog.home.agenda.details' })}
			</Button>
			<Button
				className={
					dividedButtons
						? classNames(classes.styledButtonDesktop, classes.darkerHoverButtonDesktop)
						: classes.styledButton
				}
				onClick={onClick}
				color="secondary"
				variant={dividedButtons ? 'contained' : 'text'}
				href={onClick != null ? undefined : bookingURL}
				unstyled
			>
				{formatMessage({ id: 'catalog.agenda.row.button.book' })}
			</Button>
		</div>
	);
};
