import React from 'react';
import { makeStyles } from '@mui/styles';
import { catalogTracking, CatalogTypes } from 'catalog-lib';
import { CoursesTableProps } from './CoursesTable';
import { CourseActionButtons } from './shared/CourseActionButtons';
import { DesktopCourseCredits } from './desktop/DesktopCourseCredits';
import { DesktopCourseParticipation } from './desktop/DesktopCourseParticipation';
import { CourseOtherSchedulesLink } from './shared/CourseOtherSchedulesLink';
import { CourseCapacityBadge } from './shared/CourseCapacityBadge';
import { CourseTitle } from './desktop/CourseTitle';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import {
	BOOKING_URLS,
	LOCALE_TO_DOMAIN,
	Types,
	useChangeURL,
	useHydrationContext,
	useMembershipContext
} from 'common';

const useStyles = makeStyles(
	(theme) => ({
		styledTable: {
			width: '100%',
			borderCollapse: 'collapse',

			'& td': {
				paddingBlock: theme.spacing(1.5),
				color: theme.palette.primary.main,
				height: 64
			},

			'& tr': {
				cursor: 'pointer',

				'&:hover > td': {
					backgroundColor: 'rgba(0, 0, 0, 0.04)'
				}
			}
		},
		datesColumn: {
			fontWeight: 700
		},
		rightCell: {
			borderRadius: '0 0.75rem 0.75rem 0',
			paddingInlineEnd: theme.spacing(2)
		},
		leftCell: {
			borderRadius: theme.spacing(1.5, 0, 0, 1.5),
			paddingInlineStart: theme.spacing(3)
		},
		countryCode: {
			color: '#59748D',
			fontSize: 14,
			fontWeight: 700
		}
	}),
	{ name: 'DesktopTable' }
);

export const DesktopTable = ({ courses, variant, displayMoreCoursesLink }: CoursesTableProps) => {
	const classes = useStyles();
	const router = useRouter();
	const changeURL = useChangeURL();
	const { isMember } = useMembershipContext();
	const { locale, formatMessage } = useIntl();
	const { isHydrated } = useHydrationContext();

	const handleOnDemandClick = (event: React.MouseEvent, course: CatalogTypes.CourseTableItem) => {
		event.stopPropagation(); // to avoid "goToCourse" invokation
		if (isMember) {
			// TODO: do not hardcode `/ondemand-kurs/` link. Use CATALOG_URLS.onDemandAccreditationCourse instead
			window.location.href = `/ondemand-kurs/${course.id}`;
		} else {
			changeURL(BOOKING_URLS.memberships, 'catalog');
		}
	};

	const goToCourse = (course: CatalogTypes.CourseTableItem, index: number) => {
		if (course.contentType !== Types.ContentType.OnDemandAccredited) {
			catalogTracking.courseClicked(course, { locale, index });
		}
		router.push(course.courseURL);
	};

	const domain = LOCALE_TO_DOMAIN[locale];

	const shouldShowCourseTitle = variant === 'MULTIPRODUCT' || variant === 'ONDEMAND';

	return (
		<table className={classes.styledTable}>
			<tbody>
				{courses.map((course, index) => (
					<tr
						data-testid="course-row"
						key={course.id}
						onClick={(event) => {
							if ((event.target as HTMLElement).tagName !== 'A') {
								goToCourse(course, index);
							}
						}}
					>
						<td className={classes.leftCell}>
							{shouldShowCourseTitle && <CourseTitle {...course} variant={variant} />}
						</td>
						<td className={classes.datesColumn}>
							{variant === 'ONDEMAND'
								? `${formatMessage({ id: 'common.valid-until' })} `
								: ''}
							{course.courseDates}
						</td>
						{!displayMoreCoursesLink && (
							<td>{isHydrated && <CourseOtherSchedulesLink {...course} />}</td>
						)}
						<td>
							<DesktopCourseParticipation {...course} variant={variant} />
							<CourseCapacityBadge capacityStatus={course.capacityStatus} />
						</td>
						<td>
							<DesktopCourseCredits {...course} />
						</td>
						<td>
							{course.contentLocale && domain === 'ch' && (
								<span className={classes.countryCode}>
									{course.contentLocale.substring(0, 2).toUpperCase()}
								</span>
							)}
						</td>
						<td className={classes.rightCell}>
							{isHydrated && (
								<CourseActionButtons
									onClick={
										course.contentType === Types.ContentType.OnDemandAccredited
											? (e) => handleOnDemandClick(e, course)
											: undefined
									}
									{...course}
									dividedButtons
								/>
							)}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};
