import React from 'react';
import { contentUtils, CatalogTypes } from 'catalog-lib';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	creditsContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(0.5),
		maxWidth: 200
	},
	styledText: {
		fontWeight: 400,
		fontSize: 14,
		color: '#59748D'
	},
	fullCreditsTooltip: {
		backgroundColor: '#F6F6F6',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		color: '#59748D',
		fontWeight: 400,
		fontSize: 14
	}
}));

type CourseCreditsProps = Pick<CatalogTypes.CourseTableItem, 'courseCredits'>;

export const DesktopCourseCredits = ({ courseCredits }: CourseCreditsProps) => {
	const classes = useStyles();
	const courseCreditArray = courseCredits.split(contentUtils.ContentCreditsSeparator);

	let collapsedCreditsString = courseCreditArray[0] || '';
	if (courseCreditArray.length > 1) {
		collapsedCreditsString += ` (+${courseCreditArray.length - 1})`;
	}

	const creditsJSX =
		courseCreditArray.length > 1 ? (
			<Tooltip
				title={courseCredits}
				placement="top-start"
				classes={{ tooltip: classes.fullCreditsTooltip }}
			>
				<span className={classes.styledText}>{collapsedCreditsString}</span>
			</Tooltip>
		) : (
			<span className={classes.styledText}>{collapsedCreditsString}</span>
		);

	return <div className={classes.creditsContainer}>{creditsJSX}</div>;
};
