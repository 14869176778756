import React from 'react';
import { CatalogTypes } from 'catalog-lib';
import makeStyles from '@mui/styles/makeStyles';
import { Types } from 'common';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(
	(theme) => ({
		badgesContainer: {
			display: 'flex',
			gap: 4
		},
		badge: {
			display: 'flex',
			padding: 7,
			paddingBlock: 4,
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: 2,
			width: 'fit-content',
			borderRadius: 4,
			border: '0.5px solid',
			fontSize: 10,
			lineHeight: '11.75px',
			textAlign: 'center'
		},
		onSiteSoldOut: {
			borderColor: theme.palette.error.dark + '4D',
			color: theme.palette.error.dark
		},
		onSiteNearlySoldOut: {
			borderColor: '#CF701A4D',
			color: '#CF701A'
		},
		onSiteSoldOutLivestreamAvailable: {
			borderColor: '#40691C4D',
			color: '#40691C'
		},
		badgeText: {
			fontSize: 10,
			lineHeight: '11.75px',
			fontWeight: 500
		}
	}),
	{ name: 'CourseCapacityBadge' }
);

type CourseCapacityBadgeProps = Pick<CatalogTypes.CourseTableItem, 'capacityStatus'>;

export const CourseCapacityBadge = ({ capacityStatus }: CourseCapacityBadgeProps) => {
	const classes = useStyles();
	const { formatMessage } = useIntl();

	let badges = null;
	if (capacityStatus === Types.ContentCapacityStatus.OnsiteNearlySoldOut) {
		badges = (
			<div
				className={classNames(
					classes.badge,
					classes.onSiteNearlySoldOut,
					'mui_courseCapacityBadge'
				)}
			>
				{formatMessage({ id: 'common.product.capacity-badge.on-site-nearly-sold-out' })}
			</div>
		);
	} else if (capacityStatus === Types.ContentCapacityStatus.OnsiteSoldOut) {
		badges = (
			<div className={classNames(classes.badge, classes.onSiteSoldOut)}>
				{formatMessage({ id: 'common.product.capacity-badge.sold-out' })}
			</div>
		);
	} else if (capacityStatus === Types.ContentCapacityStatus.OnsiteSoldOutLivestreamAvailable) {
		badges = (
			<>
				<div className={classNames(classes.badge, classes.onSiteSoldOut)}>
					{formatMessage({ id: 'common.product.capacity-badge.on-site-sold-out' })}
				</div>
				<div
					className={classNames(classes.badge, classes.onSiteSoldOutLivestreamAvailable)}
				>
					{formatMessage({ id: 'common.product.capacity-badge.livestream-available' })}
				</div>
			</>
		);
	}

	return <div className={classes.badgesContainer}>{badges}</div>;
};
