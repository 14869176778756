import React from 'react';
import { CatalogTypes } from 'catalog-lib';
import { makeStyles } from '@mui/styles';
import { useLocaleData } from 'common';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	styledDatesContainer: {
		fontWeight: 600,
		fontSize: 14,
		lineHeight: '20px',
		whiteSpace: 'nowrap',

		[theme.breakpoints.down(380)]: {
			whiteSpace: 'normal'
		}
	},
	countryCode: {
		color: '#59748D',
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 700,
		marginRight: 'auto'
	}
}));

type CourseDatesContainerProps = Pick<
	CatalogTypes.CourseTableItem,
	'courseDates' | 'contentLocale'
> & {
	isOnDemandTable?: boolean;
};

export const CourseDatesContainer = ({
	courseDates,
	contentLocale,
	isOnDemandTable
}: CourseDatesContainerProps) => {
	const classes = useStyles();
	const { formatMessage } = useIntl();
	const { domain } = useLocaleData();
	return (
		<>
			<span className={classes.styledDatesContainer}>
				{isOnDemandTable ? `${formatMessage({ id: 'common.valid-until' })} ` : ''}
				{courseDates}
			</span>
			{contentLocale && domain === 'ch' && (
				<span className={classes.countryCode}>
					| {contentLocale.substring(0, 2).toUpperCase()}
				</span>
			)}
		</>
	);
};
