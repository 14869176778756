import React from 'react';
import { catalogTracking, CatalogTypes } from 'catalog-lib';
import { CoursesTableProps } from './CoursesTable';
import { makeStyles } from '@mui/styles';
import { AccordionCard } from './mobile/AccordionCard';
import { SingleProductCard } from './mobile/SingleProductCard';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	mobileTableContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1)
	}
}));

export const MobileTable = ({ courses, variant }: CoursesTableProps) => {
	const classes = useStyles();
	const { locale } = useIntl();

	const MobileCardComponent =
		variant === 'MULTIPRODUCT' || variant === 'ONDEMAND' ? AccordionCard : SingleProductCard;

	const handleCourseClick = (course: CatalogTypes.CourseTableItem, index: number) => {
		catalogTracking.courseClicked(course, { locale, index });
	};

	return (
		<div className={classes.mobileTableContainer}>
			{courses.map((course, index) => (
				<MobileCardComponent
					key={course.id}
					{...course}
					variant={variant}
					onClick={() => handleCourseClick(course, index)}
				/>
			))}
		</div>
	);
};
