import React from 'react';
import { useIsDesktop } from 'common';
import { CatalogTypes } from 'catalog-lib';
import { MobileTable } from './MobileTable';
import { DesktopTable } from './DesktopTable';

export interface CoursesTableProps {
	courses: Array<CatalogTypes.CourseTableItem>;
	variant: 'MULTIPRODUCT' | 'SINGLEPRODUCT' | 'ONDEMAND';
	displayMoreCoursesLink?: boolean;
}

export const CoursesTable = (props: CoursesTableProps) => {
	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return <DesktopTable {...props} />;
	} else {
		return <MobileTable {...props} />;
	}
};
