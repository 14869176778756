import React, { useCallback, useState } from 'react';
import {
	Accordion,
	AccordionDetails as AccordionDetailsContainer,
	AccordionSummary as AccordionSummaryContainer
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CatalogTypes } from 'catalog-lib';

import { CoursesTableProps } from '../CoursesTable';
import { AccordionDetails } from './AccordionDetails';
import { AccordionSummary } from './AccordionSummary';

const useStyles = makeStyles((theme) => ({
	styledAccordion: {
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRadius: '6px',
		backgroundColor: 'white',
		boxShadow: 'none',

		'&:before': {
			display: 'none'
		},

		'& .MuiAccordionSummary-root': {
			padding: theme.spacing(2, 1)
		},

		'& .MuiAccordionSummary-content': {
			margin: 0,
			display: 'flex',
			flexDirection: 'column',
			minWidth: 0
		},

		'&.Mui-expanded': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)'
		}
	},
	styledAccordionSummary: {
		alignItems: 'start',
		color: theme.palette.primary.main
	},
	styledAccordionDetails: {
		margin: 0,
		padding: 0
	}
}));

type AccordionCardProps = CatalogTypes.CourseTableItem &
	Pick<CoursesTableProps, 'variant'> & {
		onClick?: () => void;
	};

/**
 * This component renders a course card in the mobile view when viewing courses from multiple products. When this is the case, we need to show the product name, and a product icon, unlike in a single product view.
 */
export const AccordionCard = (props: AccordionCardProps) => {
	const { variant, onClick, ...course } = props;
	const [isExpanded, setIsExpanded] = useState(false);
	const classes = useStyles();

	const handleClick = useCallback(() => {
		setIsExpanded(!isExpanded);
	}, [isExpanded]);

	return (
		<Accordion
			className={classes.styledAccordion}
			disableGutters
			TransitionProps={{ unmountOnExit: true }}
			expanded={isExpanded}
		>
			<AccordionSummaryContainer
				onClick={handleClick}
				className={classes.styledAccordionSummary}
			>
				<AccordionSummary
					isAccordionExpanded={isExpanded}
					course={course}
					variant={variant}
				/>
			</AccordionSummaryContainer>
			<AccordionDetailsContainer className={classes.styledAccordionDetails}>
				<AccordionDetails course={course} onClick={onClick} />
			</AccordionDetailsContainer>
		</Accordion>
	);
};
