import React from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { CatalogTypes } from 'catalog-lib';

import { CourseProductIcon } from '../shared/CourseProductIcon';
import { CourseDatesContainer } from './CourseDatesContainer';
import { CourseParticipationCity } from './CourseParticipationCity';
import { CourseOtherSchedulesLink } from '../shared/CourseOtherSchedulesLink';
import { MobileCourseParticipation } from './MobileCourseParticipation';
import type { CoursesTableProps } from '../CoursesTable';
import { MobileCourseCredits } from './MobileCourseCredits';

const useStyles = makeStyles((theme) => ({
	accordionSummaryContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	accordionSummaryFirstRow: {
		display: 'flex',
		flexDirection: 'row',
		color: theme.palette.primary.main,
		justifyContent: 'space-between',
		marginBottom: 16,
		minWidth: 0
	},
	accordionSummarySecondRow: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(0.5)
	},
	accordionSummaryThirdRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: theme.spacing(0.5),
		margin: '14px 0',
		fontWeight: 400,
		fontSize: 14,
		color: theme.palette.primary[500]
	},
	accordionExpandIcon: {
		color: theme.palette.primary.main,
		width: theme.spacing(4),
		height: theme.spacing(4),
		boxSizing: 'content-box',
		transition: theme.transitions.create('transform')
	},
	iconExpanded: {
		transform: 'rotate(180deg)'
	},
	firstRowLeftContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		minWidth: 0
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start',
		minWidth: 0
	},
	courseTitle: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		margin: 0,
		fontWeight: 600
	},
	courseTitleBrand: {
		fontSize: 14,
		fontWeight: 400
	},
	keepTogether: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 8
	}
}));

type AccordionSummaryProps = {
	course: CatalogTypes.CourseTableItem;
	isAccordionExpanded: boolean;
} & Pick<CoursesTableProps, 'variant'>;

export const AccordionSummary = ({
	course,
	isAccordionExpanded,
	variant
}: AccordionSummaryProps) => {
	const classes = useStyles();

	return (
		<div className={classes.accordionSummaryContainer}>
			<div className={classes.accordionSummaryFirstRow}>
				<div className={classes.firstRowLeftContainer}>
					<CourseProductIcon url={course.courseIconUrl} color={course.courseIconColor} />
					<div className={classes.titleContainer}>
						<span className={classes.courseTitle}>{course.courseTitle.title}</span>
						<span className={classNames(classes.courseTitle, classes.courseTitleBrand)}>
							{course.courseTitle.brand}
						</span>
					</div>
				</div>
				<img
					className={classNames(classes.accordionExpandIcon, {
						[classes.iconExpanded]: isAccordionExpanded
					})}
					src="https://bk-public-prod.storage.googleapis.com/public/static/arrow-down-type-2.svg"
					alt=""
				/>
			</div>
			<div className={classes.accordionSummarySecondRow}>
				<CourseDatesContainer {...course} isOnDemandTable={variant === 'ONDEMAND'} />
				<CourseOtherSchedulesLink {...course} />
			</div>
			<div className={classes.accordionSummaryThirdRow}>
				<MobileCourseParticipation {...course} variant={variant} />
				<CourseParticipationCity {...course} />
			</div>
			<MobileCourseCredits
				courseCredits={course.courseCredits}
				variant={isAccordionExpanded ? 'expanded' : 'collapsed'}
			/>
		</div>
	);
};
